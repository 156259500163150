/**@jsx jsx*/
import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { jsx } from "@emotion/core"
import axios from "axios"
import queryString from "query-string"

import Layout from "../components/layout"
import { serverURL } from "../../variables"
import { smallMT } from "../components/spacing"
import ProductCard from "../components/productCard"

const Container = styled.section`
  position: relative;
  z-index: 2;
  grid-column-start: 2;
  grid-row: 2 / 4;
`
const productURL = id => `${serverURL}/api/users/products/${id}`
const Pay = () => {
  const [product, setProduct] = useState({
    type: "",
    id: "",
    price: "",
    name: "",
    description: "",
    accountName: "",
    stripeUserId: "",
  })
  useEffect(() => {
    const queryParams =
      typeof window !== "undefined" && queryString.parse(window.location.search)
    const { id } = queryParams
    console.log(id)
    axios
      .get(productURL(id))
      .then(res => {
        const product = res.data
        setProduct({
          type: product.type,
          id: id,
          price: product.price,
          name: product.name,
          description: product.description,
          accountName: product.accountName,
          stripeUserId: product.stripeUserId,
        })
      })
      .catch({})
  }, [])

  return (
    <Layout>
      <Container>
        <ProductCard
          css={smallMT}
          id={product.id}
          accountName={product.accountName}
          name={product.name}
          price={product.price}
          desc={product.description}
          type={product.type}
        />
      </Container>
    </Layout>
  )
}

export default Pay
